import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { IconGrid } from "./IconGrid";
import * as Colored from "../src/icons/App/Colored";
import * as Monochrome from "../src/icons/App/Monochrome";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons-app"
    }}>{`Icons App`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "colored"
    }}>{`Colored`}</h2>
    <IconGrid icons={Colored} mdxType="IconGrid" />
    <h2 {...{
      "id": "monochrome"
    }}>{`Monochrome`}</h2>
    <IconGrid icons={Monochrome} mdxType="IconGrid" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      